var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c(
          "q-form",
          { ref: "editForm" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "위험형태 상세" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "초기화", icon: "autorenew" },
                              on: { btnClicked: _vm.reset },
                            })
                          : _vm._e(),
                        _vm.editable
                          ? _c("c-btn", {
                              attrs: { label: "LBLSAVE", icon: "save" },
                              on: { btnClicked: _vm.save },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-text", {
                        attrs: {
                          editable: _vm.editable,
                          required: true,
                          label: "위험형태",
                          name: "col1",
                        },
                        model: {
                          value: _vm.riskType.col1,
                          callback: function ($$v) {
                            _vm.$set(_vm.riskType, "col1", $$v)
                          },
                          expression: "riskType.col1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("c-radio", {
                        attrs: {
                          editable: _vm.editable,
                          comboItems: _vm.useFlagItems,
                          label: "LBLUSEFLAG",
                          name: "useFlag",
                        },
                        model: {
                          value: _vm.riskType.useFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.riskType, "useFlag", $$v)
                          },
                          expression: "riskType.useFlag",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }